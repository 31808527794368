import TTLCache from "@isaacs/ttlcache";
import type {
  AuthorPerm,
  HiveContent,
  ParsedAccount,
  ParsedAccounts,
  PossibleHiveContent,
  UnreadNotifications,
  ParsedNotification,
  TrendingTag,
  fetchTrendingPostsType
} from "~/utils/hive";
import {
  FullAccount,
  FollowCount,
  getDynamicGlobalProperties,
  fetchCommunities,
  fetchTrendingTags,
  isCommunity,
  fetchCommunityContext,
  fetchHiveTrendingPosts,
  callHiveClient,
  callHiveBatched,
  fetchAccountIgnores,
  authorPermToString,
  fetchAccount,
  fetchAccounts,
  fetchContent,
  fetchReplies,
  fetchFollowCount,
  fetchUnreadNotifications,
  stripHiveContent
} from "~/utils/hive";
import type { FeaturedThreadTags, PossibleThreadContent, ThreadIndexed } from "~/utils/thread";
import { persistentDevCache } from "~/utils/dev";
import type { ScotContent } from "~/utils/scot";
import {
  fetchLatestPosts,
  fetchScotContent,
  fetchScotTokenInfo,
  fetchTrendingPosts,
  stripScotContent
} from "~/utils/scot";
import type { TokenPrices } from "~/utils/coingecko";
import { fetchCoingeckoPrices } from "~/utils/coingecko";
import type { PublishPost } from "./transactions";
import { isSSR } from "./ssr";
import type { CommunitiesSorting } from "~/routes/communities";
import { buildUrl, get } from "./fetchers";
import { BACKUP_ADDRESS, SERVER_ADRESS } from "./leocache";
import type { ShortContent } from "./types/shorts.type";

export enum GenericCache {
  FeaturedThreadTags,
  UnreadNotifications,
  LatestPosts,
  TrendingPosts,
  TokenPrices,
  ScotDenom,
  ScotToken,
  DynamicGlobalProperties,
  GetCommunities,
  Communities,
  TrendingTags,
  IgnoredUsers,
  Shorts
}

export enum SiteMapCache {
  SiteMap
}

function chunkArray(array, chunkSize) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

function divideSet(set: Set, numberOfSets: number) {
  const arrayFromSet = Array.from(set);
  const result = [];
  const arrayLength = arrayFromSet.length;
  const itemsPerSet = Math.ceil(arrayLength / numberOfSets);

  for (let i = 0; i < numberOfSets; i++) {
    const startIndex = i * itemsPerSet;
    const endIndex = startIndex + itemsPerSet;
    const subSetArray = arrayFromSet.slice(startIndex, endIndex);
    const subSet = new Set(subSetArray);
    result.push(subSet);
  }

  return result;
}

let accountDisposalHolder = new Set([]);
let contentDisposalHolder = new Set([]);

class Cache {
  public sitemap: TTLCache<string, SiteMapCache> = new TTLCache({
    max: 15,
    ttl: 30 * 60 * 1_000
  });
  private contents: TTLCache<string, HiveContent> = new TTLCache({
    max: 5000,
    ttl: 5 * 60 * 1_000,
    dispose: (value, key, reason) => {
      if (!isSSR()) return;
      if (reason !== "stale") return;
      if (contentDisposalHolder.size > 20) {
        let authorPerms = [...contentDisposalHolder].map((key: string) => {
          let [author, permlink] = key.split("/");
          return { author, permlink };
        });

        void (async function () {
          await cache.getContents(authorPerms);
          accountDisposalHolder = new Set([]);
        })();
      } else {
        //console.log("Added content to holder", contentDisposalHolder.size)
        if (!contentDisposalHolder.has(key as never)) contentDisposalHolder.add(key as never);
      }
    }
  });
  private replies: TTLCache<string, HiveContent> = new TTLCache({
    max: Infinity,
    ttl: 2 * 60 * 1_000
  });
  public generic: TTLCache<GenericCache, any> = new TTLCache({
    max: 5000,
    ttl: 15 * 60 * 1_000
  });
  public community: TTLCache<string, any> = new TTLCache({
    max: 100,
    ttl: 24 * 60 * 60 * 1_000
  });
  public premiums: TTLCache<string, any> = new TTLCache({
    max: Infinity,
    ttl: 30 * 60 * 1_000
  });
  public article_feeds: TTLCache<string, any> = new TTLCache({
    max: Infinity,
    ttl: 1 * 60 * 1_000
  });
  public accounts: TTLCache<string, ParsedAccount> = new TTLCache({
    max: 10000,
    ttl: 3 * 60 * 1_000,
    dispose: (value, key, reason) => {
      if (!isSSR()) return;
      if (reason !== "stale") return;
      if (accountDisposalHolder.size > 240) {
        console.log("Account disposal holder emptied with size", accountDisposalHolder.size);
        divideSet(accountDisposalHolder, 3).forEach(accountSet => {
          void (async function () {
            await cache.getAccounts([...accountSet]);
          })();
        });
        accountDisposalHolder = new Set([]);
      } else {
        // console.log("Added account to holder", accountDisposalHolder.size);
        if (!accountDisposalHolder.has(key as never)) accountDisposalHolder.add(key as never);
      }
    }
  });
  private account_names: TTLCache<string, string> = new TTLCache({
    max: Infinity,
    ttl: Infinity
  });
  private notifications: TTLCache<number, ParsedNotification> = new TTLCache({
    max: 1,
    ttl: 2 * 60 * 1_000
  });
  private scotContents: TTLCache<string, ScotContent> = new TTLCache({
    max: Infinity,
    ttl: 5 * 60 * 1_000
  });

  public shorts: TTLCache<string, ShortContent[]> = new TTLCache({
    max: 200,
    ttl: 5 * 60 * 1_000
  });

  public accountShorts: TTLCache<string, ShortContent[]> = new TTLCache({
    max: 600,
    ttl: 15 * 60 * 1_000
  });

  private account_locale: TTLCache<string, any> = new TTLCache({
    max: 10000,
    ttl: 60 * 60 * 1_000
  })

  
  public useTranslator(account?: string) {
    let t;
    if (isSSR()) {
      t = this.account_locale.get(account || "");
    } else {
      t = this.account_locale.get("")
    }
    return { t }
  }

  public setAccountTranslator(account: string, translator: any) {
    this.account_locale.set(account, translator)
  }

  public async getCommunitiesSorted(sorting: CommunitiesSorting) {
    const communities = this.community.get(sorting);
    if (communities !== undefined) {
      return communities;
    } else {
      const result = await fetchCommunities({
        limit: 20,
        sort: sorting,
        query: null,
        observer: "",
        last: ""
      });
      this.community.set(sorting, result);
      return result;
    }
  }

  public async fetchHiveTrendingPosts(params: fetchTrendingPostsType): Promise<HiveContent[]> {
    const { tag, sort } = params;
    const cacheName = `${tag}-${sort}`;
    const articles = this.article_feeds.get(cacheName);

    if (articles !== undefined) {
      return articles;
    } else {
      const articles = await callHiveClient("bridge", "get_ranked_posts", params, "https://api.deathwing.me");
      this.article_feeds.set(cacheName, articles);
      return articles;
    }
  }

  public async getCommunityContext(communityId: string) {
    const communityContext = this.community.get(`context-${communityId}`);
    if (communityContext !== undefined) {
      return communityContext;
    } else {
      const result = fetchCommunityContext(communityId);
      this.community.set(`context-${communityId}`, result);
      return result;
    }
  }

  public async getTrendingCommunityPosts(communityId: string) {
    const trendingPosts = this.community.get(`trending-${communityId}`);
    if (trendingPosts !== undefined) {
      return trendingPosts;
    } else {
      const result = await fetchHiveTrendingPosts({
        limit: 24,
        tag: communityId,
        sort: "trending",
        observer: ""
      });
      this.community.set(`trending-${communityId}`, result);
      return result;
    }
  }

  public async getAccountName(account: string) {
    const accountName = this.account_names.get(account);
    if (accountName !== undefined) {
      return accountName;
    } else {
      const parsedAccount = this.getAccount(account);
      const extractedName = (await parsedAccount)?.posting_json_metadata?.profile?.name ?? account;
      this.account_names.set(account, extractedName);
      return extractedName;
    }
  }
  public async getPremium(_account: string) {
    if (!_account) {
      return { is_premium: false, timestamp: "1970-11-18T17:37:03" };
    }

    const account = _account.trim().toLocaleLowerCase("en-US").replaceAll("@", "").replaceAll(" ", "");

    let premium = this.premiums.get(account);

    if (premium !== undefined) {
      return premium;
    }
    let premiumState;
    try {
      premiumState = await fetch(`${SERVER_ADRESS}/premium/check/${account}`).then(x => x.json());
      this.premiums.set(account, premiumState);
    } catch {
      try {
        premiumState = await fetch(`${BACKUP_ADDRESS}/premium/check/${account}`).then(x => x.json());
        this.premiums.set(account, premiumState);
      } catch {
        premiumState = { is_premium: false, timestamp: "1970-11-18T17:37:03" };
      }
    }

    return premiumState;
  }
  public async getContent(authorPerm: AuthorPerm): Promise<PossibleHiveContent> {
    if (authorPerm === undefined) return {};
    const key = authorPermToString(authorPerm);
    const content = this.contents.get(key);
    if (content !== undefined) {
      return content;
    }

    const sanitizedPermlink = authorPerm.permlink.split("?")[0];
    try {
      const fetchedContent = await fetchContent({
        author: authorPerm.author,
        permlink: sanitizedPermlink
      }).then(stripHiveContent);
      this.contents.set(key, fetchedContent);
      return fetchedContent;
    } catch (_) {
      return null;
    }
  }
  public async updateContent(authorPerm: AuthorPerm) {
    const key = authorPermToString(authorPerm);
    try {
      const fetchedContent = await fetchContent(authorPerm).then(stripHiveContent);
      this.contents.set(key, fetchedContent);
      return fetchedContent;
    } catch (_) {
      return false;
    }
  }
  public getContents(authorPerms: AuthorPerm[]): Promise<PossibleHiveContent[]> {
    return Promise.all(authorPerms.map(x => this.getContent(x)));
  }
  public setContent(authorPerm: AuthorPerm, content: PublishPost) {
    const key = authorPermToString(authorPerm);
    return this.contents.set(key, {
      active_votes: [],
      author_reputation: 0,
      category: "",
      parent_author: "",
      parent_permlink: "",
      json_metadata: "",
      last_update: "",
      created: "",
      depth: 0,
      children: 0,
      cashout_time: "",
      root_author: "",
      ...content
    });
  }
  public async getReplies(authorPerm: AuthorPerm): Promise<PossibleHiveContent> {
    const key = authorPermToString(authorPerm);
    const replies = this.replies.get(key);
    if (replies !== undefined) {
      return replies;
    }

    try {
      const fetchedReplies = await fetchReplies(authorPerm);
      this.replies.set(key, fetchedReplies);
      return fetchedReplies;
    } catch (_) {
      console.error(_);
      return null;
    }
  }
  public async getThread(thread: ThreadIndexed): Promise<PossibleThreadContent | null> {
    if (thread?.deleted) {
      return thread;
    }
    try {
      const content = await this.getContent(thread);
      return thread;
    } catch (error) {
      return null;
    }
  }

  public getThreadsEmpty(threads: ThreadIndexed[]): PossibleThreadContent[] {
    return threads.map(thread => ({ thread, content: null }));
  }
  public async getRewardFund() {
    const rewardFund = this.generic.get("rewardFund");

    if (rewardFund !== undefined) {
      return rewardFund;
    }

    const fetchedRewardFund = await callHiveClient("condenser_api", "get_reward_fund", ["post"]);
    this.generic.set("rewardFund", fetchedRewardFund);
    return fetchedRewardFund;
  }

  public getTribeInfo = async () => {
    const info = this.generic.get("tribeInfo");

    if (info !== undefined) {
      return info;
    }

    const fetchedInfo = await get(buildUrl("https://scot-api.hive-engine.com", "info?token=LEO"));
    this.generic.set("tribeInfo", fetchedInfo);
    return fetchedInfo;
  };
  public async getThreads(threads: ThreadIndexed[]): Promise<PossibleThreadContent[]> {
    return Promise.all(threads.map(x => this.getThread(x)));
  }
  // public async getFeaturedThreadTags(): Promise<FeaturedThreadTags> {
  //   const tags = this.generic.get(GenericCache.FeaturedThreadTags);
  //   if (tags !== undefined) {
  //     return tags;
  //   }

  //   const fetchedTags = await leoCacheClient.fetchFeaturedTags();
  //   this.generic.set(GenericCache.FeaturedThreadTags, fetchedTags);
  //   return fetchedTags;
  // }

  public accountExists(accountName: string): boolean {
    const account = this.accounts.get(accountName);

    return !!account;
  }
  public async getAccount(accountName: string, renew?: boolean): Promise<ParsedAccount> {
    const account = this.accounts.get(accountName);

    if (account !== undefined && renew !== true) {
      return account;
    }

    const fetchedAccount = await fetchAccount(accountName, renew);

    this.accounts.set(accountName, fetchedAccount as any);

    return fetchedAccount as ParsedAccount;
  }

  public getAccountFromThread(thread: ThreadIndexed): Promise<ParsedAccount> {
    return this.getAccount(thread.author);
  }

  public async getAccounts(accountNames: string[]): Promise<ParsedAccounts> {
    const accounts = Array.from(new Set(accountNames)).map(x => [x, this.accounts.get(x)] as const);
    const missing = [] as string[];
    const ready = [] as Readonly<[string, ParsedAccount]>[];

    for (const [accountName, account] of accounts) {
      if (account === undefined) {
        missing.push(accountName);
      } else {
        ready.push([accountName, account]);
      }
    }

    if (missing.length !== 0) {
      const fetchedMissing = await fetchAccounts(missing);

      fetchedMissing.forEach(async (account, index) => {
        this.accounts.set(account.name, account as unknown as ParsedAccount);
      });

      ready.push(...(fetchedMissing as ParsedAccount[]).map(account => [account.name, account] as const));
    }

    return Object.fromEntries(ready);
  }
  public getAccountsFromAuthorPerms(authorPerms: (AuthorPerm | null)[]): Promise<ParsedAccounts> {
    return this.getAccounts(
      authorPerms.flatMap(authorPerm => {
        if (authorPerm === null) {
          return [];
        }
        return [authorPerm.author];
      })
    );
  }
  public getAccountsFromThreads(threads: ThreadIndexed[]): Promise<ParsedAccounts> {
    const accountsToBeFetched = threads.map(({ author }) => author);
    threads.forEach(({ first_child }) => first_child && accountsToBeFetched.push(first_child.author));
    return this.getAccounts(accountsToBeFetched);
  }
  public async getAccountsFromNotifications(notifications: ParsedNotification[]): Promise<ParsedAccounts> {
    return this.getAccounts(
      notifications.flatMap(({ content }) => {
        if (content === null) {
          return [];
        }
        if (content.parentContent === null) {
          return [content.content.author];
        }
        return [content.content.author, content.parentContent.author];
      })
    );
  }
  public async getUnreadNotifications(accountName: string): Promise<UnreadNotifications> {
    const cached = this.generic.get<UnreadNotifications & { accountName: string }>(GenericCache.UnreadNotifications);
    if (cached !== undefined && cached.accountName === accountName) {
      return cached;
    }

    const unreadNotifications = {
      ...(await fetchUnreadNotifications(accountName)),
      accountName
    };
    this.generic.set(GenericCache.UnreadNotifications, unreadNotifications);

    return unreadNotifications;
  }
  public async getLatestPosts(limit?: number): Promise<PossibleHiveContent[]> {
    const cached = this.generic.get<HiveContent[]>(GenericCache.LatestPosts);
    if (cached !== undefined) {
      return cached;
    }

    const latestPostsScot = await fetchLatestPosts();
    const latestPosts = await this.getContents(limit ? latestPostsScot : latestPostsScot.slice(0, 5));

    this.generic.set(GenericCache.LatestPosts, latestPosts, {
      ttl: 60 * 1_000
    });

    return latestPosts;
  }
  public async getTrendingPosts(limit?: number): Promise<PossibleHiveContent[]> {
    const cached = this.generic.get<HiveContent[]>(GenericCache.TrendingPosts);
    if (cached !== undefined) {
      return cached;
    }

    const trendingPostsScot = await fetchTrendingPosts(limit);
    const trendingPosts = await this.getContents(limit ? trendingPostsScot : trendingPostsScot.slice(0, 5));

    this.generic.set(GenericCache.TrendingPosts, trendingPosts, {
      ttl: 10 * 60 * 1_000
    });

    return trendingPosts;
  }
  public async getScotContent(authorPerm: AuthorPerm): Promise<ScotContent> {
    const key = authorPermToString(authorPerm);
    const cached = this.scotContents.get(key);
    if (cached !== undefined) {
      return cached;
    }

    const scotContent = await fetchScotContent(authorPerm).then(stripScotContent);
    this.scotContents.set(key, scotContent);

    return scotContent;
  }
  public async getTokenPrices(): Promise<TokenPrices> {
    const cached = this.generic.get<TokenPrices>(GenericCache.TokenPrices);
    if (cached !== undefined) {
      return cached;
    }

    let tokenPrices;

    tokenPrices = await fetchCoingeckoPrices([
      "hive",
      "bitcoin",
      "hive_dollar",
      "ethereum",
      "dash",
      "BNB",
      "ripple",
      "matic",
      "dogecoin",
      "link",
      "monero",
      "curve-dao-token",
      "polycub",
      "cub-finance",
      "bep20-leo",
      "pancakeswap",
      "thorchain",
      "splinterlands",
      "basic-attention-token"
    ]);

    const currencies = await (await fetch("https://open.er-api.com/v6/latest/USD")).json();
    tokenPrices["currencies"] = currencies.rates;

    this.generic.set(GenericCache.TokenPrices, tokenPrices, {
      ttl: 3 * 60 * 1000
    });

    return tokenPrices;
  }
  public async getDynamicGlobalProperties(): Promise<any> {
    const cached = this.generic.get<any>(GenericCache.DynamicGlobalProperties);
    if (cached !== undefined) {
      return cached;
    }

    const DynamicGlobalProperties = await getDynamicGlobalProperties();
    this.generic.set(GenericCache.DynamicGlobalProperties, DynamicGlobalProperties);

    return DynamicGlobalProperties;
  }

  public async getTrendingTags(limit: number): Promise<any[]> {
    const cached = this.generic.get<number>(GenericCache.TrendingTags);
    if (cached !== undefined) {
      return cached;
    }

    const trendingTagsInfo = await fetchTrendingTags(limit);
    const trendingTagsParsed = trendingTagsInfo
      .filter((x: TrendingTag) => x.name !== "")
      .filter((x: TrendingTag) => !isCommunity(x.name))
      .map((x: TrendingTag) => x.name);
    this.generic.set(GenericCache.TrendingTags, trendingTagsParsed);

    return trendingTagsParsed;
  }
  public async getCommunities(limit: number): Promise<any[]> {
    const cached = this.generic.get<number>(GenericCache.Communities);
    if (cached !== undefined) {
      return cached;
    }

    const communitiesInfo = await fetchCommunities({
      limit
    });
    this.generic.set(GenericCache.Communities, communitiesInfo);

    return communitiesInfo;
  }
  public async getScotDenom(): Promise<number> {
    const cached = this.generic.get<number>(GenericCache.ScotDenom);
    if (cached !== undefined) {
      return cached;
    }

    const scotTokenInfo = await fetchScotTokenInfo();
    const denom = 10 ** scotTokenInfo.precision;
    this.generic.set(GenericCache.ScotDenom, denom);

    return denom;
  }
  public async getScotToken(token: string): Promise<object> {
    const cached = this.generic.get<object>(GenericCache.ScotToken);
    if (cached !== undefined) {
      return cached;
    }

    const scotTokenInfo = await fetchScotTokenInfo(token);
    this.generic.set(GenericCache.ScotToken, scotTokenInfo);

    return scotTokenInfo;
  }
  public async getIgnoredUsers(account: string): Promise<object> {
    const cached = this.generic.get<object>(GenericCache.IgnoredUsers);
    if (cached !== undefined) {
      return cached;
    }

    let ignoredUsers = await fetchAccountIgnores(account);
    ignoredUsers = ignoredUsers.map(x => x.following);
    this.generic.set(GenericCache.ScotToken, ignoredUsers);

    return ignoredUsers;
  }

  public async getLatestShorts(page?: string): Promise<{ shorts: ShortContent[]; nextCreated: string }> {
    const shorts = this.shorts.get(page || "newest");
    if (shorts !== undefined) {
      return shorts;
    } else {
      try {
        const response = await fetch(`${SERVER_ADRESS}/shorts/feed/${page || "newest"}`);
        const result = await response.json();

        this.shorts.set(page || "newest", result);
        return result;
      } catch {
        return [];
      }
    }
  }

  public async getTrendingShorts(
    limit: number,
    next_index: number,
    session_id: string
  ): Promise<{
    shorts: ShortContent[];
    next_index: number;
    session_id: string;
  }> {
    const page = Math.floor(next_index / limit);

    try {
      const response = await fetch(`${SERVER_ADRESS}/shorts/trending/${page}/${limit}/${session_id}`);
      const result = await response.json();
      return result;
    } catch {
      return [];
    }
  }

  public async getAccountShorts(account: string): Promise<ShortContent[]> {
    const normalizedAccount = account.toLowerCase().replaceAll("@", "");

    const shorts = this.accountShorts.get(normalizedAccount);
    if (shorts !== undefined) {
      return shorts;
    } else {
      try {
        const response = await fetch(`${SERVER_ADRESS}/shorts/${normalizedAccount}`);
        const result = await response.json();

        this.accountShorts.set(normalizedAccount, result);
        return result;
      } catch {
        return [];
      }
    }
  }

  public async getSingleShort(author: string, permlink: string): Promise<ShortContent | null> {
    const shorts = this.accountShorts.get(author);

    if (shorts !== undefined) {
      const short = shorts.find((x: ShortContent) => x.discussion.permlink === permlink);

      if (short) return short;
    } else {
      try {
        const response = await fetch(`${SERVER_ADRESS}/shorts/${author}`);
        const result = await response.json();

        this.accountShorts.set(author, result);

        const short = result.find((x: ShortContent) => x.discussion.permlink === permlink);

        return short;
      } catch {
        return null;
      }
    }
  }
}

export const cache = persistentDevCache("cache", () => new Cache());
